import {
    Component,
    ViewChild,
    ComponentFactoryResolver,
    ApplicationRef,
    Injector,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ElementRef,
    Type,
    Input,
    ChangeDetectorRef,
    QueryList,
    Inject,
    NgZone,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    ConfiguratorState,
    SharedFacade,
    ICC_PAGE_DATA,
    InfoService,
    _,
    ModalService,
    ConfiguratorOptions,
    ReturnButtonVariant,
    ThemeService,
    ReturnButton,
    CloseModalConfigurator,
} from '@icc/configurator/shared';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { configuratorQuery } from './+state/root.selectors';
import { ComponentPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import { filter, map, take, tap, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfiguratorStepsService } from './step/steps.service';
import { BreakpointObserver, Breakpoints, BreakpointState  } from '@angular/cdk/layout';
import { MatIconRegistry } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { fromConfiguratorActions } from './+state/root.actions';
import { NotificationsPanelComponent } from './notifications-panel/notifications-panel.component';
import { SystemsService } from '@icc/legacy/configurator/steps/window/systems/systems.service';
import { DrawService, core, logger, AppConfigFactory, APP_CONFIG, ConfigurationsService, EventBusService, ConfiguratorsDataService, UserService } from '@icc/common';
import { ConfiguratorFacade } from 'libs/configurator/shared/src/lib/+state/configurator.facade';
import { ConfiguratorInfoService } from './info.service';
import { trigger, transition, style, animate, state, query, group, stagger } from '@angular/animations';
import { readFirst } from '@nrwl/nx/testing';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { PriceDetailsComponent } from 'libs/configurator/shared/src/lib/price-details/price-details.component';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { DefaultConfigurationsService, SashLayoutPageComponent, WindowFacade } from '@icc/configurator/window';
import { InitService } from './init.service';
import { MatDialog } from '@angular/material/dialog';
import { ConstructionIssuesModalComponent } from './construction-issues-modal/construction-issues-modal.component';
import { AccessoriesListPageStoreService } from '../../../../libs/configurator/accessory/src/lib/accessories-list-page/accessories-list-page-store.service';
@Component({
    selector: 'icc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('messageTrigger', [
            transition(':enter', [
                style({ transform: 'translateY(-100%)' }),
                animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({ transform: 'translateY(-100%)' })),
            ]),
        ]),
        trigger('progressBar', [
            transition(':enter', [
                style({ transform: 'translateY(-100%)' }),
                animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({ transform: 'translateY(-100%)' })),
            ]),
        ]),
        trigger('search', [
            transition('0 => 1', [
                query('#search:enter', [
                    style({ transform: 'translateX(100%)', width: 0 }),
                    animate('200ms ease-in', style({ transform: 'translateX(0%)', width: '100%' })),
                ]),
            ]),
            transition('1 => 0', [
                group([
                    query('#search:leave', [
                        style({ transform: 'translateX(0%)', width: '100%', opacity: 1 }),
                        animate('200ms ease-in-out', style({ transform: 'translateX(220%)', width: '100%', opacity: 0 })),
                    ]),
                    query('h1:enter', [
                        style({ opacity: 0 }),
                        stagger(200, [
                            animate('200ms ease-in', style({ opacity: 1 })),
                        ]),
                    ]),
                    query('.toolbarIcons:enter, .spacer:enter', [
                        style({ opacity: 0 }),
                        stagger(200, [
                            animate('20ms ease-in', style({ opacity: 1 })),
                        ])
                    ]),
                ]),
            ])
        ]),
    ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() options?: any;

    @ViewChild('pageContainer', { read: ElementRef }) pageContainer:
        | ElementRef
        | undefined;
    @ViewChild('filtersContainer', { read: ElementRef }) filtersContainer:
        | ElementRef
        | undefined;
    @ViewChild('snav') sidenav: MatSidenav | undefined;
    @ViewChild('sideFilters') filtersSidenav: MatSidenav | undefined;
    @ViewChild(MatProgressBar) progressBar: MatProgressBar | undefined;
    drawingSide: 'inner' | 'outer' = 'inner';

    @ViewChild('searchInput')
    set searchInputElement(element: ElementRef) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    accessories = {
        categories: {},
        subcategories: {},
    }
    selectedCategory = { id: Category.allCategories, name: this.translateService.instant('INTERFACE|Wszystkie') };
    selectedSubCategory =  { id: Category.allSubcategories, name: this.translateService.instant('INTERFACE|Wszystkie') };

    languages: any = {};
    selectedLanguage = '';
    currencies: any = {};
    selectedCurrency = {};


    private pageOutlet: DomPortalOutlet | undefined;
    private filtersOutlet: DomPortalOutlet | undefined;
    currentApplicationVersion = environment.appVersion;
    priceData$ = new Subject();
    steps$ = this.stepsService.steps$.pipe(map(steps => steps.filter(step => step.enable)));
    hasSteps$ = this.steps$.pipe(map(steps => steps.length > 1));
    selectedStep$ = this.stepsService.selectedStep$;
    openedPage$ = this.stepsService.openedPage$;
    openedFilterSheet$ = this.stepsService.openedFilterSheet$;
    openedMessage$ = this.infoService.openedMessage$;
    configuratorInitialized$ = this.sharedFacade.configuratorInitialized$;
    savingButton$ = this.sharedFacade.savingButton$;
    searchControl = new FormControl('');
    width$ = this.windowFacade.width$;
    height$ = this.windowFacade.height$;
    pricePerItemAfterDiscounts: boolean;
    isBlockedAddToOffer$ = this.windowFacade.isBlockedAddToOffer$;
    currency: any = {};
    price: any = {};
    isHandset = false;
    isTablet = false;
    isSmallDesktop = false;
    isDesktop = false;
    isMobile = false;
    isFullscreen = false;
    isSummaryHidden = false;
    forceReturnButton = false;
    isStepperHidden = false;
    hiddenPrice = false;
    currentStepId = 0;
    step: any = undefined;
    project = 'icc';
    logo = '';
    stepTitle = '';
    pageTitle: string | null = null;

    stepDescriptionEnabled = false;
    stepMessage = '';
    stepsDescriptions: any = {};

    activeSearch = false;

    pageMenuOptions: {
        title: string | Observable<string>;
        component?: Type<any>;
        componentData?: any;
        callback?: () => void;
        icon?: {
            fontSet?: string;
            fontIcon?: string;
            ligature?: string;
        };
        show?: () => boolean;
    }[] = [];

    pageToolbarOptions: {
        title: string | Observable<string>;
        component?: Type<any>;
        componentData?: any;
        callback?: () => void;
        icon?: {
            fontSet?: string;
            fontIcon?: string;
            ligature?: string;
        };
        show?: () => boolean;
    }[] = [];

    private pageOptions: {
        title: string | Observable<string>;
        component?: Type<any>;
        componentData?: any;
        callback?: () => void;
        icon?: {
            fontSet?: string;
            fontIcon?: string;
            ligature?: string;
        };
        show?: () => boolean;
    }[] = [];

    private searchOption = {
        title: _('INTERFACE|Wyszukaj'),
        callback: () => {
            this.searching = true;
        },
        show: () => this.activeSearch,
        icon: {
            ligature: 'search',
        },
    };

    private notificationsOption = {
        title: _('INTERFACE|Uwagi'),
        callback: () => {
            this.openNotificationsPanel();
        },
        show: () => this.messagesCount > 0,
        icon: {
            ligature: 'notifications',
        },
    };

    price$ = this.configuratorFacade.price$;
    messagesCount = 0;

    openedPreview = false;
    openedConstructionPreview = false;
    previewDetails = false;
    drawOptions = this.drawService.options;
    returnButton: ReturnButtonVariant = 'OK';
    additionalReturnButtons: ReturnButton[];
    RETURN_BUTTON_ENUM = {
        ADD:                        _('INTERFACE|Dodaj'),
        SAVE:                       _('INTERFACE|Zapisz'),
        OK:                         _('INTERFACE|OK'),
        GENERATE_THUMBNAIL:         _('CONFIGURATOR|Generuj miniaturę'),
        ADD_TO_BASKET:              _('INTERFACE|Dodaj do koszyka'),
        ADD_TO_OFFER:               _('INTERFACE|Dodaj do ofery'),
        PRINT:                      _('INTERFACE|Generuj PDF')
    }

    translationLoading = true;

    configuratorOptions?: ConfiguratorOptions;
    homeButtonText = '';

    searching = false;
    modalConfigurator = false;
    expandSummary = false;
    customConstructionName: string  | undefined= '';
    quantity: number | undefined = 1;
    showOfferDataInputs: boolean;
    showConstructionIssuesModalButton: boolean;
    offerDiscountToPosition = false;
    private unsubscribe$ = new Subject<void>();
    flipSymbols = false;

    // eslint-disable-next-line max-params
    // eslint-disable-next-line max-statements
    constructor(
        private store: Store<{
            root: ConfiguratorState;
        }>,
        private componentFactoryResolver: ComponentFactoryResolver,
        private configuratorsDataService: ConfiguratorsDataService,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        private stepsService: ConfiguratorStepsService,
        private breakpointObserver: BreakpointObserver,
        private sharedFacade: SharedFacade,
        private configuratorFacade: ConfiguratorFacade,
        public translateService: TranslateService,
        private cdr: ChangeDetectorRef,
        private systemsService: SystemsService,
        private accessoresListPageStoreService: AccessoriesListPageStoreService,
        public drawService: DrawService,
        private infoService: ConfiguratorInfoService,
        private matIconRegistry: MatIconRegistry,
        private hotkeysService: HotkeysService,
        private modalService: ModalService,
        private themeService: ThemeService,
        private titleService: Title,
        private initService: InitService,
        private windowFacade: WindowFacade,
        private configurationsService: ConfigurationsService,
        private dialog: MatDialog,
        private eventBusService: EventBusService,
        private defaultConfigurationsService: DefaultConfigurationsService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        private ngZone: NgZone,
        private userService: UserService,
    ) {
        if (this.userService.get().flip_symbols) {
            this.flipSymbols = true;
        }
        matIconRegistry.registerFontClassAlias('icc-icon');
        this.breakpointObserver.observe(['(max-width: 660px)'])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((state: BreakpointState) => {
                this.isHandset = state.matches;
            });

        this.breakpointObserver.observe(['(min-width: 661px) and  (max-width: 1000px)'])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((state: BreakpointState) => {
                this.isTablet = state.matches;
            });

        this.breakpointObserver.observe(['(min-width: 1001px) and (max-width: 1200px)'])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((state: BreakpointState) => {
                this.isSmallDesktop = state.matches;
            });

        this.breakpointObserver.observe(['(min-width: 1201px) and (max-width: 1600px)'])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((state: BreakpointState) => {
                this.isDesktop = state.matches;
            });

        this.stepsService.openedPage$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(pageData => {
            if (pageData?.page){
                this.closePreview();
            }
        });

        this.initService.loaded$
            .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$)).subscribe(isLoaded=>{
            if (isLoaded){
                this.drawOptions = this.drawService.options;
                this.drawService.init();
            }
        })
        this.sharedFacade.languageAndProject$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(option => {
                if (option.language) {
                    translateService.addLangs([option.language]);
                    translateService.setDefaultLang(option.language);
                    translateService.use(option.language).pipe(take(1)).subscribe(
                        () => {},
                        () => {},
                        () => {
                            this.translationLoading = false;
                        }
                    );
                }
                if (option.theme) {
                    if (option.domain?.theme_favicon) {
                        option.theme.favicon = "/files/domains/" + option.domain.theme_favicon;
                    }
                    this.themeService.setTheme(option.theme);
                    this.project = 'dynamic';
                    document.body.classList.add('dynamic-theme');
                } else {
                    this.project = option.projectName || 'icc';
                }
                this.logo = option.logo;

                if (option.domain && this.document) {
                    try {
                        const nodeBeginHead = document.createRange()
                            .createContextualFragment(option.domain.insert_head_begin);
                        this.document.head.prepend(nodeBeginHead);

                        const nodeEndHead = document.createRange()
                            .createContextualFragment(option.domain.insert_head_end);
                        this.document.head.append(nodeEndHead);

                        const nodeEndBody = document.createRange()
                            .createContextualFragment(option.domain.insert_body_end);
                        this.document.body.append(nodeEndBody);
                    } catch (error) {
                        logger.error(error);
                    }
                }
            });


        this.sharedFacade.fullscreen$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(fullscreen => {
                this.isFullscreen = fullscreen;
        });
        this.sharedFacade.preview$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(preview => {
                if (preview.isPreviewEnabled === true){
                    this.previewDetails = false;
                    if (preview.drawData && this.drawOptions){
                        this.drawOptions = preview.drawData;
                        this.drawOptions.dimensions.type = 'extended';
                    }
                }
                this.openedConstructionPreview = preview.isPreviewEnabled;
                this.openedPreview = preview.isPreviewEnabled;
        });
        this.sharedFacade.messages$.pipe(map(m => m.length)).subscribe(count => {
            this.messagesCount = count;
        });

        this.hotkeysService.add(new Hotkey(['mod+shift+4', 'mod+shift+l'], (event: KeyboardEvent): boolean => {
            this.ngZone.run(() => {
                this.modalService.open({
                    pageComponent: PriceDetailsComponent,
                });
            });
            return false;
        }));

        this.sharedFacade.options$.pipe(takeUntil(this.unsubscribe$)).subscribe(options => {
            this.configuratorOptions = options;
        });

        translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            translateService.get('CONFIGURATOR|Konfigurator').toPromise()
                .then(data => {
                    if (this.configuratorOptions) {
                        this.titleService.setTitle(data + ' • ' + this.configuratorOptions.config.Settings.App.AppName);
                    }
                });
        })

        this.sharedFacade.currency$.pipe(takeUntil(this.unsubscribe$)).subscribe(currency => {
            this.currency = currency;
            this.selectedCurrency = currency;
        });

        this.sharedFacade.currencies$.pipe(takeUntil(this.unsubscribe$)).subscribe(currencies => {
            this.currencies = currencies;
        });

        this.stepsService.selectedStep$.pipe(takeUntil(this.unsubscribe$)).subscribe(step => {
            if (step) {
                this.currentStepId = step.stepIndex;
            }
        });

        this.sharedFacade.returnButton$.pipe(takeUntil(this.unsubscribe$)).subscribe(button => {
            this.returnButton = button;
        });

        this.sharedFacade.additionalReturnButtons$.pipe(takeUntil(this.unsubscribe$)).subscribe(buttons => {
            this.additionalReturnButtons = buttons;
        });

        this.sharedFacade.activeSearch$.pipe(takeUntil(this.unsubscribe$)).subscribe(active => {
            this.activeSearch = active;
            if (!this.activeSearch) {
                this.searching = false;
            }
        });

        this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
            this.sharedFacade.updateSearch(value);
        });

        this.sharedFacade.getIsModalConfigurator$.pipe(takeUntil(this.unsubscribe$)).subscribe(active => {
            this.modalConfigurator = active;
        });
        this.configurationsService.conf$.pipe(takeUntil(this.unsubscribe$)).subscribe(data=>{
            this.customConstructionName = data.Current?.Title;
            this.quantity = data.Current?.Quantity;
            this.calculatePriceByQuantity(data.Current?.Quantity);
            this.cdr.markForCheck();
        });
        this.price$.pipe(takeUntil(this.unsubscribe$)).subscribe((data)=>{
            data.priceBeforePromotions = this.quantity ? data.priceBeforePromotions * this.quantity : data.priceBeforePromotions;
            data.priceAfterDiscountsInCurrency = this.quantity ? data.priceAfterDiscountsInCurrency * this.quantity : data.priceAfterDiscountsInCurrency;
            data.price = this.quantity ? data.price * this.quantity : data.price;
            this.price = data;
            setTimeout(()=>{
                this.cdr.detectChanges();
            }, 0);
        })
        this.drawingSide = this.drawOptions.base.side === "outer" ? 'outer' : 'inner';
        this.showOfferDataInputs = this.config().preset === 'b2b' && !config().IccConfig.Configurators.configurationQuantityWithButtonsAndCustomName;
        this.pricePerItemAfterDiscounts = this.config().IccConfig.Configurators.priceAfterDiscountsInCurrencyInSummaryB2C;
        this.offerDiscountToPosition = this.config().IccConfig.Offer.offerDiscountToPosition;

        this.sharedFacade.getStepsDescriptions$.pipe(takeUntil(this.unsubscribe$)).subscribe(stepsDescriptions => {
            this.stepsDescriptions = stepsDescriptions;
        });
        this.showConstructionIssuesModalButton = this.config().IccConfig.Configurators.showConstructionIssuesModal || this.config().preset === 'b2b';
        this.hiddenPrice = this.config().preset === 'b2c' && this.config().hidePricesInB2cConfigurator;
        this.infoService.dismissInfoModal();
    }
    onNameChange(value: string){
        this.configurationsService.setConstructionName(value);
    }

    onQuantityChange(value: number){
        this.configurationsService.setQuantity(value);
    }

    isDefaultConfigurationAvailable() {
        return this.defaultConfigurationsService.isDefaultConfigurationAvailable();
    }

    confirmSettingDefaultConfiguration() {
        this.defaultConfigurationsService.confirmSettingDefaultConfiguration();
    }

    ngOnInit(): void {
        if (this.isBrowserLanguageEnabled()) {
            this.languages = this.getLanguages();
            this.selectedLanguage = this.getSelectedLanguage();
        }
        this.store.dispatch(
            new fromConfiguratorActions.LoadConfigurator({ options: this.options })
        );
        if (this.options && this.options.init$) {
            this.options.editMode = this.configurationsService.conf.Edit;
            this.options.init$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
                this.store.dispatch(
                    new fromConfiguratorActions.LoadConfigurator({ options: this.options })
                );
            });
        }
        if (window.screen.width >= 360) {
            this.expandSummary = true;
        }
    }

    showBrowserLanguageSettings() {
        return this.isBrowserLanguageEnabled() && Object.keys(this.languages).length > 1;
    }

    isBrowserLanguageEnabled() {
        return this.config().preset === 'b2c' && this.config().languageCodes;
    }

    showCurrenciesSelect() {
        return this.config().IccConfig.Configurators.multiCurrency && this.config().preset === 'b2c' && this.currencies.length > 1;
    }

    showConstructionIssuesModal(){
        const dialogRef = this.dialog.open(ConstructionIssuesModalComponent,{
            panelClass: 'construction-issues-modal'
        })
    }

    ngAfterViewInit(): void {
        if (this.pageContainer) {
            this.pageOutlet = new DomPortalOutlet(
                this.pageContainer.nativeElement,
                this.componentFactoryResolver,
                this.applicationRef,
                this.injector
            );

            // eslint-disable-next-line max-statements
            this.openedPage$.pipe(takeUntil(this.unsubscribe$)).subscribe(step => {
                if (step && step.component && this.pageOutlet) {
                    this.step = step;
                    this.pageOutlet.detach();
                    const injectionTokens = new WeakMap<any, any>([[ICC_PAGE_DATA, step.data]]);
                    const injector = new PortalInjector(this.injector, injectionTokens);
                    const stepPortal = new ComponentPortal(step.component, undefined, injector);
                    const componentRef = this.pageOutlet.attach(stepPortal);

                    if (this.isFilterByCategoriesOptionSelected(step)) {
                        this.pageTitle = ' ';
                        this.stepTitle = ' ';
                    } else if (step.page) {
                        this.pageTitle = this.getName(componentRef.instance.title, this.configuratorOptions);
                    } else {
                        this.stepTitle = this.getName(componentRef.instance.title, this.configuratorOptions);
                        this.pageTitle = null;
                    }

                    this.setAccessoriesCategory(step);

                    if(this.stepMessage != '') {
                        this.infoService.dismissWarning(this.stepMessage)
                    }
                    this.stepDescriptionEnabled = false;
                    this.stepMessage = '';
                    const stepId = componentRef.instance.stepId;
                    if(stepId && this.stepsDescriptions && this.stepsDescriptions[stepId]) {
                        this.stepDescriptionEnabled = true;
                        this.stepMessage = this.stepsDescriptions[stepId];
                    }

                    this.pageOptions = [
                        this.searchOption,
                        ...componentRef.instance.options,
                        this.notificationsOption,
                    ];

                    if (this.isFullscreen !== componentRef.instance.fullscreen) {
                        if (this.isFullscreen) {
                            this.sharedFacade.disableFullscreen();
                        } else {
                            this.sharedFacade.enableFullscreen();
                        }
                    }
                    this.isSummaryHidden = componentRef.instance.isSummaryHidden;
                    this.forceReturnButton = componentRef.instance.forceReturnButton;
                    this.isStepperHidden = componentRef.instance.isStepperHidden;

                    if (this.filtersOutlet && this.filtersSidenav) {
                        this.filtersOutlet.detach();
                        this.filtersSidenav.opened = false;
                    }
                    this.stopSearching();
                    this.cdr.reattach();
                    this.cdr.detectChanges();
                }
            });
        }
        if (this.filtersContainer && this.filtersSidenav) {
            this.filtersOutlet = new DomPortalOutlet(
                this.filtersContainer.nativeElement,
                this.componentFactoryResolver,
                this.applicationRef,
                this.injector
            );
            this.openedFilterSheet$.pipe(takeUntil(this.unsubscribe$)).subscribe(step => {
                if (step && step.component && this.filtersOutlet && this.filtersSidenav) {
                    this.filtersOutlet.detach();
                    const injectionTokens = new WeakMap<any, any>([[ICC_PAGE_DATA, step.data]]);
                    const injector = new PortalInjector(this.injector, injectionTokens);
                    const stepPortal = new ComponentPortal(step.component, undefined, injector);
                    const componentRef = this.filtersOutlet.attach(stepPortal);
                    this.filtersSidenav.opened = true;
                    this.cdr.detectChanges();
                } else if (this.filtersOutlet && this.filtersSidenav) {
                    this.filtersOutlet.detach();
                    this.filtersSidenav.opened = false;
                    this.cdr.detectChanges();
                }
                this.stopSearching();
            });
            this.filtersSidenav.openedChange.pipe(takeUntil(this.unsubscribe$)).subscribe((opened: boolean) => {
                if (!opened) {
                    this.sharedFacade.closeFilterSheet();
                }
            });
        }

        this.showConfigurationNotFoundMessage(
            this.configuratorOptions?.configurationNotFound,
            this.configuratorOptions?.configurationShortcode
        );
    }

    private isFilterByCategoriesOptionSelected(step: any = this.step) {
        return this.config().filterAccessoriesByCategories && step?.data?.hideTitle && step.data.forIt === 'configuration';
    }

    private setAccessoriesCategory(step: any) {
        if (Array.isArray(step?.data?.categories) && this.isFilterByCategoriesOptionSelected(step)) {
            this.accessories.categories = {
                id: Category.allCategories,
                name: this.translateService.instant('INTERFACE|Wszystkie'),
            };
            this.accessories.categories = [this.accessories.categories, ...step.data.categories];
            const categories = {
                category: { id: Category.allCategories, name: this.translateService.instant('INTERFACE|Wszystkie') },
                subcategory: { id: Category.allSubcategories, name: this.translateService.instant('INTERFACE|Wszystkie') }
            }
            this.selectedCategory = categories.category;
            this.selectedSubCategory = categories.subcategory;
            this.accessoresListPageStoreService.emitSelectedCategory(categories);
        } else {
            this.accessories.categories = [];
        }
    }

    showSubcategories() {
        return (
            Object.keys(this.accessories?.subcategories).length > 1 &&
            Object.keys(this.accessories?.categories).length > 1 &&
            this.selectedCategory.id !== Category.allCategories
        );
    }

    changeCategory(category: { name: string; id: Category; }) {
        this.selectedCategory.name = category.name || category.id;
        this.selectedCategory.id = category.id;

        const categories = {
            category: this.selectedCategory,
            subcategory: {
                id: Category.allSubcategories,
                name: this.translateService.instant('INTERFACE|Wszystkie')
            }
        }
        this.setSubcategoriesBasedOnParentCategory(category);
        this.accessoresListPageStoreService.emitSelectedCategory(categories);
    }

    private setSubcategoriesBasedOnParentCategory(category: { name: string; id: Category; }) {
        this.accessories.subcategories = {
            id: Category.allSubcategories,
            name: this.translateService.instant('INTERFACE|Wszystkie'),
        };
        const subcategoriesFromParentCategory = this.getSubcategoriesFromParentCategoryBasedOnId(
            Number(category.id)
        );
        this.accessories.subcategories = [
            this.accessories.subcategories,
            ...this.step.data.subcategories.filter((subCategory: any) =>
                subcategoriesFromParentCategory?.find((c) => Number(subCategory.id) === Number(c))
            ),
        ];

        if (!Object.values(this.accessories.subcategories).find((s: any) => s.id === this.selectedSubCategory.id)) {
            this.selectedSubCategory.id = Category.allSubcategories;
            this.selectedSubCategory.name = this.translateService.instant('INTERFACE|Wszystkie');
        }
    }

    changeSubcategory(category: { name: string; id: Category; }) {
        this.selectedSubCategory.name = category.name || category.id;
        this.selectedSubCategory.id = category.id;

        const categories = {
            category: this.selectedCategory,
            subcategory: this.selectedSubCategory
        }
        this.accessoresListPageStoreService.emitSelectedCategory(categories);
    }

    getSubcategoriesFromParentCategoryBasedOnId(id: number) {
        const parentCategories = this.configuratorsDataService.data.windowAccessoriesCategories.filter((c: any) => c.parent_id);

        if (!parentCategories.length) {
            return [];
        }

        return this.accessoresListPageStoreService.getCategories(id, parentCategories);
    }

    ngOnDestroy(): void {
        if (this.pageOutlet) {
            this.pageOutlet.detach();
        }
        if (this.filtersOutlet) {
            this.filtersOutlet.detach();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.closeConfigurator();
    }

    switchStep(stepId: string) {
        this.ngZone.run(() => {
            this.closeSidebar();
            if (stepId === 'details' && (this.isSmallDesktop || this.isTablet || this.isHandset)) {
                this.openPreview();
            } else {
                this.stepsService.selectStep(stepId);
            }
        });
    }

    goToNextStep() {
        this.ngZone.run(() => {
            if (this.stepsService.getStep() + 1 === this.stepsService.getLastStep().step && (this.isSmallDesktop || this.isTablet || this.isHandset || this.isFullscreen)) {
                this.openPreview();
            } else {
                this.stepsService.goToNextStep();
            }
        });
    }

    goToPreviousStep() {
        this.ngZone.run(() => {
            this.stepsService.goToPreviousStep();
        });
    }

    menuItemHandler(option: {
        title: string;
        component?: Type<any>;
        callback?: () => void;
        componentData?: any;
    }) {
        this.closeSidebar();
        if (option.component) {
            this.stepsService.openPage(option.component, option.componentData || {});
        } else if (option.callback) {
            option.callback();
        }
    }

    getPageToolbarOptions() {
        const options = this.pageOptions.filter(o => (!o.show || o.show()) && o.icon);
        if (this.isHandset) {
            this.pageToolbarOptions = options.slice(0, 1);
        } else {
            if (options.length <= 3) {
                this.pageToolbarOptions = options;
            } else {
                this.pageToolbarOptions = options.slice(0, 2);
            }
        }
        return this.pageToolbarOptions;
    }

    getPageMenuOptions() {
        const optionsWithIcon = this.pageOptions.filter(o => (!o.show || o.show()) && o.icon);
        const options = this.pageOptions.filter(o => (!o.show || o.show()) && !o.icon);
        const pageMenuOptions = [];
        if (this.isHandset) {
            pageMenuOptions.push(...optionsWithIcon.slice(1));
        } else {
            if (optionsWithIcon.length > 3) {
                pageMenuOptions.push(...optionsWithIcon.slice(2));
            }
        }
        pageMenuOptions.push(...options);
        this.pageMenuOptions = pageMenuOptions;
        return this.pageMenuOptions;
    }

    closePage() {
        this.stepsService.closePage();
        this.dismissMessage();
    }

    dismissMessage() {
        this.infoService.dismissInfoModal();
    }

    private closeSidebar() {
        if (this.sidenav && this.sidenav.mode !== 'side') {
            this.sidenav.close();
        }
    }

    openPreview() {
        this.openedPreview = true;
        this.previewDetails = true;
        this.sharedFacade.previewAnimationDone$.next();
    }

    closePreview() {
        this.openedPreview = false;
        this.openedConstructionPreview = false
        this.sharedFacade.togglePreview({
            isPreviewEnabled: false,
            drawData: null,
        });
        this.drawOptions = this.drawService.options;
        this.drawOptions.dimensions.type = 'outer';
        this.drawService.init();
        this.drawOptions.base.side = this.drawingSide;
    }

    summaryToggle() {
        this.expandSummary = !this.expandSummary;
    }

    saveConfiguration(returnButton?: ReturnButton, conf = this.configurationsService.conf.Current) {
        if (this.config().IccConfig.Configurators.configurationQuantityWithButtonsAndCustomName) {
            this.setQuantityAndTitle(conf);
        }

        this.store.dispatch(new fromConfiguratorActions.SaveButtonClicked({returnButton}));
        this.dismissMessage();
    }

    private setQuantityAndTitle(conf = this.configurationsService.conf.Current) {
        switch (this.returnButton) {
            case 'ADD':
            case 'SAVE':
            case 'OK':
            case 'ADD_TO_OFFER':
            case 'ADD_TO_BASKET': {
                this.configurationsService.setQuantity(conf.Quantity);
                this.configurationsService.setConstructionName(conf.Title);
                if (this.initService.options) {
                    this.initService.options.quantity = Number(conf.Quantity);
                    this.initService.options.userDescription = conf.Title;
                }
                break;
            }
        }
    }

    exitConfigurator({ goToHome = false }: { goToHome?: boolean } = {}) {
        if (goToHome) {
            this.store.dispatch(new fromConfiguratorActions.ExitConfiguratorHome());
        } else {
            this.store.dispatch(new fromConfiguratorActions.ExitConfigurator());
        }
    }

    openNotificationsPanel() {
        this.stepsService.openPage(NotificationsPanelComponent);
    }

    async backIconClick(ev: MouseEvent) {
        this.exitConfigurator({ goToHome: false });
    }

    async homeIconClick(ev: MouseEvent) {
        this.exitConfigurator({ goToHome: true });
    }

    changeLanguage(languageCode: string) {
        if (languageCode && typeof languageCode === 'string') {
            this.dispatchChangeLanguage(languageCode);
        }
    }

    dispatchChangeLanguage(languageCode: string) {
        if (typeof languageCode === 'string') {
            this.store.dispatch(
                new fromConfiguratorActions.ChangeLanguage({ languageCode })
            );
        }
    }

    changeCurrency(currencyId: string) {
        const selectedCurrency = this.currencies.find((currency: { id: string; }) => currency.id === currencyId);
        this.selectedCurrency = selectedCurrency;
        this.dispatchChangeCurrency(selectedCurrency);
        this.eventBusService.post({
            key: 'changeCurrency',
            value: { }
        })
    }

    dispatchChangeCurrency(currency: any) {
        this.store.dispatch(
            new fromConfiguratorActions.ChangeCurrency(currency)
        );
    }


    getLanguages() {
        const languageCodes = this.config().languageCodes?.map((language: { code: string; }) => language.code);
        const languages: any = {};
        languageCodes.forEach((tag: string) =>
            {
                languages[tag] = new Intl.DisplayNames(tag.split('-')[0], {
                    type: 'language',
                    languageDisplay: 'standard',
                }).of(tag)
            }
        );

        return languages;
    }

    getSelectedLanguage() {
        return this.languages[this.config().CurLang];
    }

    switchDrawingSide(side: 'inner' | 'outer') {
        const switchedDrawOptions = core.copy(this.drawOptions);
        switchedDrawOptions.base.side = side;
        this.drawingSide = side;
        this.drawOptions = switchedDrawOptions;
        this.drawService.init();
        this.cdr.detectChanges();
    }

    stopSearching() {
        this.searchControl.setValue('');
        this.searching = false;
    }

    private getName(name: string | ((options?: ConfiguratorOptions) => string), options?: ConfiguratorOptions) {
        if (typeof name === 'function' ) {
            return name(options);
        }
        return name;
    }

    closeConfigurator() {
        this.sharedFacade.closeModalConfigurator({});
    }
    calculatePriceByQuantity(quantity: number | undefined) {
        const priceData = core.copy(this.price);
        priceData.price = quantity ? priceData.price * quantity : priceData.price;
        priceData.priceBeforeDiscounts = quantity ? priceData.priceBeforeDiscounts * quantity : priceData.priceBeforeDiscounts;
    }
    showConfigurationNotFoundMessage(configurationNotFound: boolean, shortcode: string) {
        if (configurationNotFound) {
            if (shortcode) {
                this.infoService.openWarning(
                    this.translateService.instant('CONFIGURATOR|Nie odnaleziono konfiguracji')
                    + " " + shortcode + ". " +
                    this.translateService.instant('CONFIGURATOR|Załadowano konfigurację startową.')
                )
            } else {
                this.infoService.openWarning(
                    this.translateService.instant('CONFIGURATOR|Nie odnaleziono konfiguracji')
                    + ". " +
                    this.translateService.instant('CONFIGURATOR|Załadowano konfigurację startową.')
                )
            }
        }
    }

    resizeDraw() {
        setTimeout(() => {
            this.eventBusService.post({
                key: 'dismissMessage',
                value: { }
            })
        }, 201);
    }

    openInfoModal() {
        this.infoService.openInfoModal(
            {
                message: this.stepMessage,
                title: ''
            }
        );
    }
}

export enum Category {
    allCategories = 'all-categories',
    allSubcategories = 'all-sub-categories',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
    class DisplayNames {
        constructor(locales?: any, options?: any);
        of(code: string): string | undefined;
    }
}
