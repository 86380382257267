import { ActiveConfiguration } from './ActiveConfiguration';
import { WindowConfiguration } from './WindowConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { TimeLimitSegment } from '@icc/common/time-limit/time-limit-functions';
import {
    ProfileSet,
    Profile,
    ProfileShape,
    Measurement,
    Shape,
    Frame,
    CouplingActive,
    SideProfileActive,
    SideProfile,
    Alignment,
    Layout,
    Coupling,
    MullionV2,
    Sash,
    FrameV2,
    DoorSizes,
    SystemPacket,
    Casing,
    FillingsCategories,
    HandlesType
} from '@icc/window';
import { MosquitoActiveConfiguration } from './MosquitoActiveConfiguration';
import { ActiveRollerShutter } from './parts/roller_shutter';
import { ActiveAccessory } from './parts/common';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { Common } from '../Common';
import { core } from '../helpers';
import { Issue } from '@icc/helpers';
import {
    IccWindowColors,
    IccWood,
    IccConstructColor,
    IccWarmEdge,
    IccAccessory,
    IccSideColors,
    IccFilling,
    DoorPortal,
    IccAccessoryColor,
    IccColor
} from '@icc/common/data-types';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { ActiveMullion } from '@icc/common/layout/active-mullion';
import { IccDoorSize, IccDoorLightsSize } from '../data-types/DoorSize';
import { IccHinge } from '../data-types/Hinge';
import { AppConfig } from '../app-config';
import { Occurrence } from '@icc/legacy/dependencies/occurrence.interface';
export class WindowActiveConfiguration implements ActiveConfiguration {
    //#region pola interfejsu
    type: 'window' | 'door' | 'hs' | 'folding_door' | 'sliding_door' = 'window';
    Name = '';
    Price = 0;
    PriceNoMargin = 0;
    PriceAfterDiscountsInCurrency = 0;
    PriceBeforePromotions = 0;
    PriceGross = 0;
    PriceGrossAfterDiscounts = 0;
    PriceGrossBeforePromotions = 0;
    Quantity = 1;
    Issues: Issue[] = [];
    PriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    PriceSegmentsBeforePromotions: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    PricePartsBeforePromotions: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    Discount = 0;
    timeLimit = 0;
    timeLimitsStack: TimeLimitSegment[] = [];
    Title = '';
    Description = '';
    Attachments = [];
    DeletedAttachments = [];
    valid: {
        system: null | boolean;
        shape: null | boolean;
        dimensions: null | boolean;
        sashes: null | boolean;
        sealColor: null | boolean;
        siliconeColor: null | boolean;
        colors: null | boolean;
        wood: null | boolean;
        profileSet: null | boolean;
        loadedMuntinsColors: null | boolean;
        loadedProfiles: null | boolean;
        mullionsProfiles: null | boolean;
        sashesProfiles: null | boolean;
        frameProfiles: null | boolean;
        loadedGlazingBeads: null | boolean;
        loadedFillings: null | boolean;
        glazingBeads: null | boolean;
        fillings: null | boolean;
        fillingsCategories: null | boolean;
        extensionsColors: null | boolean;
        extensionsSystems: null | boolean;
        muntins: null | boolean;
        muntinsColors: null | boolean;
        loadedThresholdColor: null | boolean;
        thresholdColor: null | boolean;
        loadedLippingColor: null | boolean;
        lippingColor: null | boolean;
        loadedFillingsColors: boolean;
        innerLippingColor: null | boolean;
        casing: null | boolean;
        System: null | boolean;
        panelGlazings: null | boolean;
    } = {
        system: null,
        shape: null,
        dimensions: null,
        sashes: null,
        sealColor: null,
        siliconeColor: null,
        colors: null,
        wood: null,
        profileSet: null,
        loadedMuntinsColors: null,
        loadedProfiles: null,
        mullionsProfiles: null,
        sashesProfiles: null,
        frameProfiles: null,
        loadedGlazingBeads: null,
        loadedFillings: null,
        glazingBeads: null,
        fillings: null,
        fillingsCategories: null,
        extensionsColors: null,
        extensionsSystems: null,
        muntins: null,
        muntinsColors: null,
        loadedThresholdColor: null,
        thresholdColor: null,
        loadedFillingsColors: false,
        loadedLippingColor: null,
        lippingColor: null,
        innerLippingColor: null,
        casing: null,
        System: null,
        panelGlazings: null,
    };
    PriceValid = false;

    Warranty = true;
    IsMistakeProduct = false;
    Measurements: Measurement[] = [];
    //#endregion

    //#region kształt, rozmiar, współczynniki
    Height = 1500;
    Width = 1500;
    Shape: Shape = {
        shape: 'rect',
        width: 1500,
        height: 1500,
        circuit: 6000,
        s1: 1500,
        s2: 0,
        s3: 0,
        h1: 1500,
        h2: 0,
        h3: 1500,
    };
    Weight = 0;
    doorSizes: DoorSizes = {
        sashSize: 0,
        sashSizeId: null,
        passiveSashSize: 0,
        passiveSashSizeId: null,
        shortening: 0,
        frameShortening: 0,
        topLightSize: 0,
        topLightSizeId: null,
        leftLightSize: 0,
        leftLightSizeId: null,
        rightLightSize: 0,
        rightLightSizeId: null,
        customWidth: 0,
        customHeight: 0,
        customSize: false,
        sizeIntervalCustomWidth: 0,
        sizeIntervalHeight: 0,
        standardHeight: null,
        isStandardHeightSelected: null,
        isStandardWidthSelected: null,
        shorteningAvailable: null,
      };
    doorSizesDictionary: {
        door: Record<IccDoorSize['id'], IccDoorSize>,
        doorLights: Record<IccDoorLightsSize['id'], IccDoorLightsSize>
    } = {
        door: {},
        doorLights: {}
    };
    //#endregion

    //#region kolory, drewno, wykończenie

    ColorsSashExt = false;
    Colors: IccWindowColors = {
        frame: {
            outer: null,
            inner: null,
            core: null,
            alushell: null,
        },
        sash: {
            outer: null,
            inner: null,
            core: null,
            alushell: null,
        },
    };
    Wood: IccWood | null = null;
    AlushellType: 'painted' | 'brushed' = 'brushed';
    SealColor: Partial<IccAccessoryColor> = {};
    SiliconeColor: Partial<IccAccessoryColor> = {};
    /**
     * Kolory progu
     */
    thresholdColor: IccColor | null = null;
    /**
     * Kolor listwy mocującej
     */
    lippingColor: IccColor | null = null;
    innerLippingColor: IccColor | null = null;

    //#endregion

    //#region wypełnienia
    GlazingBeadType: boolean | number = false;
    WarmEdge: Partial<IccWarmEdge> = {};
    HasWarmEdge = false;
    CustomFillings = [];
    GlazingWarranty = true;
    Glazing: any = {};
    //#endregion

    //#region akcesoria
    Accessories: IccAccessory[] = [];
    BlockedAccessories: number[] = [];
    SideAccessories: {
        sizes: {
            top: number;
            bottom: number;
            left: number;
            right: number;
        };
        top: IccAccessory[];
        bottom: IccAccessory[];
        left: IccAccessory[];
        right: IccAccessory[];
    } = {
        sizes: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        top: [],
        bottom: [],
        left: [],
        right: [],
    };
    doorPortal: DoorPortal | null = {
        id: null,
        frame_profile_ids: null,
        window_color_group_ids: null,
        selectedColor: null,
        plateType: null,
        surcharge: null,
        wallThickness: null,
        depth: null,
        portalGap: null,
        leftPortal: null,
        rightPortal: null,
        upperPortal: null,
        upperWidth: null,
        leftWidth: null,
        rightWidth: null,
        upperHeight: null,
        portalSizes: null,
        pricesForLength: null
    }
    //#endregion

    //#region okucia
    HasHandle = false;
    Handle: any = {};
    HandleOuter: any = {};
    HandleColor: any = {};
    HandleOuterColor: any = {};
    HandleHeight = 0;
    HandleType: HandlesType = 'InnerLever';
    Hinge: IccHinge = null;
    HingeColor: IccColor = null;
    Lock: any = {};
    Fitting: any = {};
    //#endregion

    //#region profile, wzmocnienia, system
    System: any = {};

    /**
     * Pakiety systemów
     */
    systemPackets: SystemPacket['id'][] = [];
    Steel: any = null;
    HasAlushell = false;
    ProfileSet: ProfileSet = {
        id: null,
        name: '',
        frameTop: null,
        frameBottom: null,
        frameSide: null,
        sash: null,
        fixedMullion: null,
        falseMullion: null,
        threshold: null,
        sashOutward: null,
        centralHandleFalseMullion: null,
        doubleHandleFalseMullion: null,
        zMullion: null,
        glazingBeadShape: null,
        systems: [],
    };
    Monoblock: any = {};
    UsedProfiles: Profile[] = [];
    UsedProfileShapes: ProfileShape[] = [];
    UsedProfilesSegments: {
        [profileId: number]: number[];
    } = {};
    weldFinishType: 'V' | 'thin' = 'V';
    drainagePosition: 'bottom' | 'front' = 'front';
    //#endregion

    //#region konstrukcja
    Frames: Frame[] = [];
    Sashes: ActiveSash[] = [];
    Mullions: ActiveMullion[] = [];
    Alignments: Alignment[] = [];
    couplings: CouplingActive[] = [];
    SideProfiles: SideProfileActive[] = [];
    /**
     * Został ustawiony profil podwaliny
     */
    foundationProfile = false;
    hasRoller = false;
    RollerShutter: ActiveRollerShutter = new ActiveRollerShutter();
    mosquito: MosquitoActiveConfiguration | null = null;

    Sill: any = {};
    HasSill = false;

    SashesType: 'Fix' | 'FixSash' | 'Func' | 'Mix' = 'Fix';
    OwnedSashesTypes = {
        window: true,
        doorActive: false,
        doorPassive: false,
        doorTopLight: false,
        doorLeftLight: false,
        doorRightLight: false
    };
    EdgeSashes: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    } = {
        top: [],
        left: [],
        right: [],
        bottom: [],
    };

    Balcony = false;
    Kicker = false;
    Layout: Layout = {
        changed: false,
        id: 0,
        conf: 'window',
        fromCode: false,
        divs: [],
        layoutCode: null,
        countSashes: 0,
        shape: 0,
        transomsOnSeperateFrames: false,
        name: '',
        sashTypes: [],
        sashes: [],
        isMirrorLayoutAvailable: false,
        equalDivision: false,
    };
    MuntinsData: {
        rWidth: number;
        width: number;
        color: IccColor | null;
        colorOut: IccColor | null;
        colorDraw: string;
        type: any;
        typeCode: any;
        duplex: boolean;
        sizeId: number;
    } = {
        rWidth: 0,
        width: 0,
        color: null,
        colorOut: null,
        colorDraw: '#FFFFFF',
        type: {},
        typeCode: 'S',
        duplex: false,
        sizeId: 0,
    };
    HasMuntins = false;
    layoutHasBeenChosen = false;
    //#endregion

    //#region one
    OneGlazing = false;
    OneFilling: {
        window: string | false,
        doorActive: string | false,
        doorActiveInner: string | false,
        doorPassive: string | false,
        doorLight: string | false,
        doorTopLight: string | false,
        doorLeftLight: string | false,
        doorRightLight: string | false
    } = {
        window: false,
        doorActive: false,
        doorActiveInner: false,
        doorPassive: false,
        doorLight: false,
        doorTopLight: false,
        doorLeftLight: false,
        doorRightLight: false
    };
    OneGlazingBead = false;
    OneGlazingBeadSash: {
        fix: number | boolean;
        sashes: number | boolean;
    } = {
        fix: false,
        sashes: false,
    };
    OneReinforcement = true;
    OneBondedGlazing = false;
    OneBondedGlazingPerSash = {
        window: false,
        doorActive: false,
        doorPassive: false,
        doorTopLight: false,
        doorLeftLight: false,
        doorRightLight: false
    };
    OneHandle: any = false;
    OneHandlesHeight: any = false;
    OneHandleSash: any = false;
    OneHandleOuterSash: any = false;
    //#endregion

    ChangedDimensions = false;
    CustomPrice = 0;

    DrawId = '';
    drawData: any = null;

    PriceElems: any = {};

    systemComparison?: boolean;

    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    Casing: Casing = {
        id: null,
        name: null,
        code: null,
        innerWidth: null,
        wallOverlap: null,
        isCasingSelected: null,
    };

    FillingsCategories: FillingsCategories = {
        id: null,
        name: null
    };

    Dependencies: [{ id: number, type: string }] = [{
        id: null,
        type: null
    }];

    InfoContext: [{ valueType: string, value: string }] = [{
        valueType: null,
        value: null
    }];

    DependenciesOccurrence: Occurrence[] = [];
    OfferInfo: any = [];

    // eslint-disable-next-line max-statements
    constructor(
        configuration?: any,
        private configuratorsDataService?: ConfiguratorsDataService | null,
        private langCode = configuration?.langCode || 'pl',
        private config: AppConfig = null
    ) {
        if (configuration) {
            if (WindowActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (WindowConfiguration.is(configuration) && configuratorsDataService) {
                    this.DependenciesOccurrence = configuration.dependenciesOccurrence
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.PriceAfterDiscountsInCurrency = configuration.priceAfterDiscountsInCurrency;
                    this.PriceGross = configuration.priceGross;
                    this.PriceGrossAfterDiscounts = configuration.priceGrossAfterDiscounts;
                    this.PriceSegments = configuration.priceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.PriceSegmentsBeforePromotions = configuration.priceSegmentsBeforePromotions;
                    this.PriceParts = configuration.priceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.PricePartsBeforePromotions = configuration.pricePartsBeforePromotions;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = config?.userDescription || configuration.title;
                    this.Quantity = config?.quantity || configuration.quantity || 1;
                    this.Description = configuration.description;
                    this.timeLimit = configuration.timeLimit;
                    this.timeLimitsStack = configuration.timeLimitsStack;
                    this.valid = configuration.valid;
                    this.valid.loadedMuntinsColors = null;
                    this.valid.loadedFillings = null;
                    this.valid.loadedGlazingBeads = null;
                    this.valid.loadedProfiles = null;

                    this.attributes = configuration.attributes;

                    this.System = configuratorsDataService.get(
                        'windowLinesAll',
                        configuration.system
                    ) || {};
                    this.systemPackets = configuration.systemPackets;
                    this.ColorsSashExt = configuration.colorsSashExt;

                    ['frame', 'sash'].forEach((part: keyof IccWindowColors) => {
                        this.Colors[part] = {
                            outer: null,
                            inner: null,
                        };
                        ['outer', 'inner', 'core', 'alushell'].forEach(
                            (side: keyof IccSideColors) => {
                                this.Colors[part][side] = null;
                                if (
                                    configuration.colors[part][side]
                                    && configuration.colors[part][side].id
                                ) {
                                    this.Colors[part][side] = configuratorsDataService.get(
                                        ['outer', 'inner'].includes(side) ? 'windowColorsAll' : 'colors',
                                        configuration.colors[part][side].id
                                    );
                                    if (this.Colors[part][side]) {
                                        this.Colors[part][side].isDefault =
                                            configuration.colors[part][side].isDefault;
                                    }
                                }
                            }
                        );
                    });

                    this.Steel = configuration.steel;
                    if (configuration.wood) {
                        this.Wood = configuratorsDataService.get('woodTypes', configuration.wood);
                    }
                    this.AlushellType = configuration.alushellType || 'brushed';
                    this.HasAlushell = configuration.hasAlushell;
                    this.Height = configuration.height;
                    this.Width = configuration.width;
                    this.Shape = configuration.shape;
                    this.doorSizes = configuration.doorSizes;
                    if (Common.isObject(configuration.dictionary?.doorSizes)) {
                        Object.keys(configuration.dictionary.doorSizes).forEach(id => {
                            this.doorSizesDictionary.door[id] = configuratorsDataService.get('doorSizes', Number(id))
                        });
                    }
                    if (Common.isObject(configuration.dictionary?.doorLightSizes)) {
                        Object.keys(configuration.dictionary.doorLightSizes).forEach(id => {
                            this.doorSizesDictionary.doorLights[id] = configuratorsDataService.get('doorLightsSizes', Number(id))
                        });
                    }
                    this.CustomFillings = configuration.customFillings;
                    this.Sashes = configuration.sashes.map(s =>
                        this.mapSash(s, configuration.dictionary, configuratorsDataService)
                    );
                    this.SashesType = configuration.sashesType;
                    this.OwnedSashesTypes = configuration.ownedSashesTypes;
                    this.EdgeSashes = {
                        top: this.Sashes.filter(
                            sash => sash.nearMullions.top === -1 && sash.nearAlignments.top === -1
                        ).map(sash => sash.id),
                        bottom: this.Sashes.filter(
                            sash =>
                                sash.nearMullions.bottom === -1 && sash.nearAlignments.bottom === -1
                        ).map(sash => sash.id),
                        left: this.Sashes.filter(
                            sash => sash.nearMullions.left === -1 && sash.nearAlignments.left === -1
                        ).map(sash => sash.id),
                        right: this.Sashes.filter(
                            sash =>
                                sash.nearMullions.right === -1 && sash.nearAlignments.right === -1
                        ).map(sash => sash.id),
                    };

                    this.SideProfiles =
                        configuration.sideProfiles.map(s =>
                            this.mapSideProfiles(s, configuration.dictionary)
                        ) || [];
                    this.Alignments = configuration.alignments || [];
                    this.couplings =
                        configuration.couplings.map(c =>
                            this.mapCoupling(c, configuration.dictionary)
                        ) || [];
                    this.Mullions = configuration.mullions.map(
                        this.mapMullion(this.Sashes, configuration.dictionary, null).bind(this)
                    );
                    this.Balcony = configuration.balcony;
                    this.UsedProfiles = Object.values(configuration.dictionary.profiles).map(p => ({
                        ...p,
                        name: p.name[this.langCode],
                    }));
                    this.UsedProfileShapes = Object.values(
                        configuration.dictionary.profileShapes
                    ).map(p => ({
                        ...p,
                        name: p.name[this.langCode],
                    }));
                    this.UsedProfilesSegments = configuration.usedProfilesSegments;
                    this.ProfileSet = configuration.profileSet;
                    this.foundationProfile = configuration.foundationProfile;

                    if (Common.isObject(configuration.layout) && configuration.layout.id) {
                        this.Layout = {
                            id: configuration.layout.id,
                            name: configuration.layout.name,
                            changed:
                                configuration.layout.divs && configuration.layout.sashes
                                    ? configuration.layout.changed
                                    : true,
                            sashes: configuration.layout.sashes,
                            divs: configuration.layout.divs,
                            conf: configuration.layout.conf,
                            fromCode: configuration.layout.fromCode,
                            layoutCode: configuration.layout.layoutCode,
                            sashTypes: configuration.layout.sashTypes,
                            countSashes: configuration.layout.countSashes,
                            shape: configuration.layout.shape,
                            transomsOnSeperateFrames: configuration.layout.transomsOnSeperateFrames,
                            isMirrorLayoutAvailable: configuration.layout.isMirrorLayoutAvailable,
                            equalDivision: configuration.layout.equalDivision,
                        };
                    } else {
                        this.Layout = {
                            id: Number(configuration.layout),
                            changed: true,
                            name: '',
                            sashes: [],
                            divs: [],
                            conf: 'window',
                            fromCode: false,
                            layoutCode: null,
                            sashTypes: [],
                            countSashes: 0,
                            shape: 0,
                            transomsOnSeperateFrames: false,
                            isMirrorLayoutAvailable: configuration.layout.isMirrorLayoutAvailable,
                            equalDivision: configuration.layout.equalDivision,
                        };
                    }

                    this.Sill = {};
                    this.HasSill = false;

                    this.OneGlazing = configuration.oneGlazing;
                    this.OneFilling = configuration.oneFilling;
                    this.FillingsCategories = configuration.fillingsCategories;
                    this.Casing = configuration.casing;
                    this.OneGlazingBead = configuration.oneGlazingBead;
                    this.OneGlazingBeadSash = configuration.oneGlazingBeadSash;

                    if (configuration.sealColor) {
                        this.SealColor =
                            configuratorsDataService.get(
                                'windowSealColors',
                                configuration.sealColor.id
                            ) || {};
                        this.SealColor.isDefault = configuration.sealColor.isDefault;
                    }
                    if (configuration.siliconeColor) {
                        this.SiliconeColor =
                            configuratorsDataService.get(
                                'windowSiliconeColors',
                                configuration.siliconeColor.id
                            ) || {};
                        this.SiliconeColor.isDefault = configuration.siliconeColor.isDefault;
                    }

                    this.OneHandle = configuration.oneHandle;
                    this.OneHandlesHeight = configuration.oneHandlesHeight;
                    this.HasHandle = configuration.hasHandle;
                    this.HandleType = configuration.handleType;
                    if (configuration.hinge) {
                        this.Hinge = configuratorsDataService.get('hinges', configuration.hinge?.id);
                        this.HingeColor = configuratorsDataService.get(
                            'windowHandlesColors',
                            configuration.hinge?.color?.id
                        );

                        if (this.HingeColor && configuration.hinge?.color) {
                            this.HingeColor.isDefault = configuration.hinge.color.isDefault;
                        }
                    }
                    if (configuration.lock) {
                        this.Lock = configuratorsDataService.get('locks', configuration.lock);
                    }
                    if (configuration.glazingSpacer && configuration.glazingSpacer) {
                        this.WarmEdge = configuratorsDataService.get(
                            'windowWarmEdges',
                            configuration.glazingSpacer
                        );
                        this.HasWarmEdge = true;
                    }

                    if (configuration.muntins) {
                        this.MuntinsData.rWidth = configuration.muntins.size;
                        this.MuntinsData.colorDraw = configuration.muntins.colorDraw;
                        this.MuntinsData.color = configuration.muntins.colorInner ? configuratorsDataService.get(
                            'colors',
                            configuration.muntins.colorInner.id
                        ) : null;
                        if (this.MuntinsData.color) {
                            this.MuntinsData.color.isDefault = configuration.muntins.colorInner.isDefault;
                        }

                        this.MuntinsData.colorOut = configuration.muntins.colorOuter ? configuratorsDataService.get(
                           'colors',
                            configuration.muntins.colorOuter.id
                        ) : null;
                        if (this.MuntinsData.colorOut) {
                            this.MuntinsData.colorOut.isDefault = configuration.muntins.colorOuter.isDefault;
                        }

                        this.MuntinsData.type = configuratorsDataService.get(
                            'windowBarTypes',
                            configuration.muntins.type.id
                        );
                        this.MuntinsData.typeCode = configuration.muntins.type.id;
                        this.MuntinsData.duplex = configuration.muntins.duplex;
                        this.MuntinsData.sizeId = configuration.muntins.sizeId;
                    }
                    this.HasMuntins = Boolean(configuration.muntins);

                    this.Fitting = configuratorsDataService.get(
                        'windowFittings',
                        configuration.fitting
                    ) || {};

                    this.Frames = configuration.frames.map(f =>
                        this.mapFrame(f, configuration.dictionary)
                    );

                    this.Accessories = configuration.accessories
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(accessory, null, configuratorsDataService)
                        );
                    this.BlockedAccessories = [];
                    this.SideAccessories.top = configuration.sideAccessories.top
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(accessory, 'top', configuratorsDataService)
                        );
                    this.SideAccessories.bottom = configuration.sideAccessories.bottom
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(accessory, 'bottom', configuratorsDataService)
                        );
                    this.SideAccessories.left = configuration.sideAccessories.left
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(accessory, 'left', configuratorsDataService)
                        );
                    this.SideAccessories.right = configuration.sideAccessories.right
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(accessory, 'right', configuratorsDataService)
                        );
                    this.SideAccessories.sizes.top = this.SideAccessories.top.reduce(
                        (prev, accessory) =>
                            (prev += accessory.count * Number(accessory.accessory.height)),
                        0
                    );
                    this.SideAccessories.sizes.bottom = this.SideAccessories.bottom.reduce(
                        (prev, accessory) =>
                            (prev += accessory.count * Number(accessory.accessory.height)),
                        0
                    );
                    this.SideAccessories.sizes.left = this.SideAccessories.left.reduce(
                        (prev, accessory) =>
                            (prev += accessory.count * Number(accessory.accessory.height)),
                        0
                    );
                    this.SideAccessories.sizes.right = this.SideAccessories.right.reduce(
                        (prev, accessory) =>
                            (prev += accessory.count * Number(accessory.accessory.height)),
                        0
                    );

                    ['top', 'bottom', 'left', 'right'].forEach(side => {
                        this.SideAccessories[side].reduce((prev, cur) => {
                            if (prev) {
                                cur.pos = prev.pos + prev.count * prev.accessory.height;
                            }
                            return cur;
                        }, null);
                    });

                    this.doorPortal = configuration.doorPortal;

                    this.Warranty = configuration.hasWarranty;
                    this.IsMistakeProduct = configuration.isMistakeProduct;
                    this.hasRoller = Boolean(configuration.rollerShutter);
                    this.Weight = configuration.parameters.weight;
                    this.weldFinishType = configuration.weldFinishType;
                    this.drainagePosition = configuration.drainagePosition;
                    this.GlazingWarranty = configuration.hasGlazingWarranty;
                    this.Measurements = configuration.measurements;
                    this.Measurements.forEach(measurement => (measurement.attachments = []));
                    this.OneBondedGlazing = configuration.oneBondedGlazing;
                    this.GlazingBeadType = configuration.glazingBeadType;

                    this.thresholdColor =
                       configuratorsDataService.get<IccColor>(
                            'colors',
                            configuration.thresholdColor ? configuration.thresholdColor.id : -1
                        )
                        || null;

                    if (this.thresholdColor) this.thresholdColor.isDefault = configuration.thresholdColor.isDefault;

                    this.lippingColor =
                        configuratorsDataService.get<IccColor>(
                            'colors',
                            configuration.lippingColor ? configuration.lippingColor.id : -1
                        )
                        || null;

                    if (this.lippingColor) this.lippingColor.isDefault = configuration.lippingColor.isDefault;

                    this.innerLippingColor =
                        configuratorsDataService.get<IccColor>(
                            'colors',
                            configuration.innerLippingColor
                                ? configuration.innerLippingColor.id
                                : -1
                        )
                        || null;

                    if (this.innerLippingColor) this.innerLippingColor.isDefault = configuration.innerLippingColor.isDefault;

                    if (configuration.rollerShutter) {
                        this.RollerShutter = new ActiveRollerShutter(
                            configuration.rollerShutter,
                            configuratorsDataService
                        );
                    }

                    this.mosquito = configuration.mosquito
                        ? new MosquitoActiveConfiguration(
                              configuration.mosquito,
                              configuratorsDataService
                          )
                        : null;
                    this.OfferInfo = configuration?.offerInfo || '';
                }
            }
        }
        this.configuratorsDataService = null;
        this.config = null;
    }

    static is(configuration: any): configuration is WindowActiveConfiguration {
        return (
            configuration
            && (configuration instanceof WindowActiveConfiguration
                || (!configuration.$version
                    && ['window', 'door', 'hs', 'folding_door', 'sliding_door'].indexOf(configuration.type) > -1))
        );
    }

    // eslint-disable-next-line max-statements
    private mapSash(
        sash: Sash,
        dict: WindowConfiguration['dictionary'],
        configuratorsDataService: ConfiguratorsDataService
    ): ActiveSash {
        const newSash = new ActiveSash(sash.id, {
            index: sash.index,
            parentId: Common.isDefined(sash.parentId) ? sash.parentId : null,
            frameId: Common.isDefined(sash.frameId) ? sash.frameId : null,
            rx: sash.x,
            ry: sash.y,
            rWidth: sash.width,
            rHeight: sash.height,
            frame: sash.frame
                ? sash.frame
                : {
                      top: {},
                      bottom: {},
                      left: {},
                      right: {},
                  },
            type: sash.type
                ? Common.extend({}, configuratorsDataService.get('sashTypes', sash.type.id))
                : {},
            divs: {
                top: sash.nearMullions.top,
                bottom: sash.nearMullions.bottom,
                left: sash.nearMullions.left,
                right: sash.nearMullions.right,
            },
            nearAlignments: {
                left: sash.nearAlignments.left,
                right: sash.nearAlignments.right,
                top: sash.nearAlignments.top,
                bottom: sash.nearAlignments.bottom,
            },
            bondedGlazing: Boolean(sash.bondedGlazing),
            panelGlazing: sash.panelGlazing
        });

        newSash.drawing = [];
        newSash.muntins = sash.muntins;
        newSash.intSashes = sash.intSashes
            ? sash.intSashes.map(s => this.mapSash(s, dict, configuratorsDataService))
            : [];
        newSash.intAlignments = sash.intAlignments ? sash.intAlignments : [];
        newSash.intMullions = sash.intMullions
            ? sash.intMullions.map(this.mapMullion(newSash.intSashes, dict, sash.id).bind(this))
            : [];
        newSash.intEdgeSashes = {
            top: sash.intAdjacentSashes ? sash.intAdjacentSashes.top : [],
            bottom: sash.intAdjacentSashes ? sash.intAdjacentSashes.bottom : [],
            left: sash.intAdjacentSashes ? sash.intAdjacentSashes.left : [],
            right: sash.intAdjacentSashes ? sash.intAdjacentSashes.right : [],
        };
        newSash.nearMullions = {
            top: sash.nearMullions.top,
            bottom: sash.nearMullions.bottom,
            left: sash.nearMullions.left,
            right: sash.nearMullions.right,
        };
        newSash.nearAlignments = {
            left: sash.nearAlignments.left,
            right: sash.nearAlignments.right,
            top: sash.nearAlignments.top,
            bottom: sash.nearAlignments.bottom,
        };
        newSash.glazing = sash.filling.custom
            ? core.fIdO(this.CustomFillings, sash.filling.id)
            : core.copy(configuratorsDataService.data.fillings.find(e => e.id === sash.filling.id))
              || core.copy(
                  configuratorsDataService.data.fillings.find(
                      e => e.id.split('.')[0] === sash.filling.id
                  )
              )
              || {};
        newSash.glazingBead = sash.glazingBead;
        newSash.panelGlazing = sash.panelGlazing
            ? configuratorsDataService.get<IccFilling>('fillings', sash.panelGlazing.id) || null
            : null;
        newSash.panelInner = sash.panelInner
            ? core.copy(configuratorsDataService.data.fillings.find(e => Number(e.id) === Number(sash.panelInner.id)))
            : null;
        newSash.bondedGlazing = Boolean(sash.bondedGlazing);
        newSash.panelType = sash.panelType || 'Inset';
        newSash.oneGlazing = sash.oneGlazing;
        newSash.oneFilling = {
            window: false,
            doorActive: false,
            doorPassive: false,
            doorTopLight: false,
            doorLeftLight: false,
            doorRightLight: false
        };
        newSash.glazingSizes = sash.fillingSizes
            ? {
                  width: sash.fillingSizes.width,
                  height: sash.fillingSizes.height,
                  area: sash.fillingSizes.area,
                  x: sash.fillingSizes.x,
                  y: sash.fillingSizes.y,
              }
            : null;
        newSash.mosquito = sash.mosquito
            ? new MosquitoActiveConfiguration(sash.mosquito, configuratorsDataService)
            : null;
        newSash.frame = sash.frame
            ? {
                  top: sash.frame.top && {
                      ...sash.frame.top,
                      reinforcement: sash.frame.top.reinforcement && {
                          ...dict.reinforcements[sash.frame.top.reinforcement],
                          name:
                              dict.reinforcements[sash.frame.top.reinforcement].name[this.langCode],
                      },
                  },
                  bottom: sash.frame.bottom && {
                      ...sash.frame.bottom,
                      reinforcement: sash.frame.bottom.reinforcement && {
                          ...dict.reinforcements[sash.frame.bottom.reinforcement],
                          name:
                              dict.reinforcements[sash.frame.bottom.reinforcement].name[
                                  this.langCode
                              ],
                      },
                  },
                  left: sash.frame.left && {
                      ...sash.frame.left,
                      reinforcement: sash.frame.left.reinforcement && {
                          ...dict.reinforcements[sash.frame.left.reinforcement],
                          name:
                              dict.reinforcements[sash.frame.left.reinforcement].name[
                                  this.langCode
                              ],
                      },
                  },
                  right: sash.frame.right && {
                      ...sash.frame.right,
                      reinforcement: sash.frame.right.reinforcement && {
                          ...dict.reinforcements[sash.frame.right.reinforcement],
                          name:
                              dict.reinforcements[sash.frame.right.reinforcement].name[
                                  this.langCode
                              ],
                      },
                  },
              }
            : {
                  top: null,
                  bottom: null,
                  left: null,
                  right: null,
              };
        newSash.lock = sash.lock;
        newSash.handle = sash.handleInner
            ? configuratorsDataService.get('windowHandlers', sash.handleInner.id)
            : {};
        newSash.handleOuter = sash.handleOuter
            ? configuratorsDataService.get('windowHandlers', sash.handleOuter.id)
            : {};
        newSash.handleColor =
            sash.handleInner && sash.handleInner.color
                ? configuratorsDataService.get('windowHandlesColors', sash.handleInner.color.id)
                : null;
        if (newSash.handleColor && sash.handleInner.color.isDefault) {
            newSash.handleColor.isDefault = sash.handleInner.color.isDefault;
        }

        newSash.handleOuterColor =
            sash.handleOuter && sash.handleOuter.color
                ? configuratorsDataService.get('windowHandlesColors', sash.handleOuter.color.id)
                : null;

        if (newSash.handleOuterColor && sash.handleOuter.color.isDefault) {
            newSash.handleOuterColor.isDefault = sash.handleOuter.color.isDefault;
        }
        newSash.rHandleY = sash.handleHeight;
        newSash.defaultHandleHeight = sash.defaultHandleHeight;
        newSash.handleHeightType = sash.handleHeightType;
        newSash.hardware = sash.hardware
            .filter(accessory => configuratorsDataService.get('windowAccessories', accessory.id) && !accessory.configurationAccessoryToSash)
            .map(accessory => new ActiveAccessory(accessory, sash.id, configuratorsDataService));
        newSash.shape = {
            shape: sash.shape
        };
        newSash.oneGlazingBead = sash.oneGlazingBead;
        newSash.canHaveDuplex = true;
        newSash.weight = sash.weight;
        if (newSash.type && sash.type) {
            newSash.type.handle_position = sash.type.handlePosition;
        }
        if (Common.isObject(newSash.frame) && Common.isObject(sash.frame)) {
            newSash.frame = {
                top: sash.frame.top && {
                    ...sash.frame.top,
                    reinforcement:
                        (sash.frame.top.reinforcement != null && {
                            ...dict.reinforcements[sash.frame.top.reinforcement],
                            name:
                                dict.reinforcements[sash.frame.top.reinforcement].name[
                                    this.langCode
                                ],
                        })
                        || null,
                },
                bottom: sash.frame.bottom && {
                    ...sash.frame.bottom,
                    reinforcement:
                        (sash.frame.bottom.reinforcement != null && {
                            ...dict.reinforcements[sash.frame.bottom.reinforcement],
                            name:
                                dict.reinforcements[sash.frame.bottom.reinforcement].name[
                                    this.langCode
                                ],
                        })
                        || null,
                },
                left: sash.frame.left && {
                    ...sash.frame.left,
                    reinforcement:
                        (sash.frame.left.reinforcement != null && {
                            ...dict.reinforcements[sash.frame.left.reinforcement],
                            name:
                                dict.reinforcements[sash.frame.left.reinforcement].name[
                                    this.langCode
                                ],
                        })
                        || null,
                },
                right: sash.frame.right && {
                    ...sash.frame.right,
                    reinforcement:
                        (sash.frame.right.reinforcement != null && {
                            ...dict.reinforcements[sash.frame.right.reinforcement],
                            name:
                                dict.reinforcements[sash.frame.right.reinforcement].name[
                                    this.langCode
                                ],
                        })
                        || null,
                },
            };
        }
        newSash.glazing.selectedColor = !sash.filling.color
            ? null
            : {
                inner: this.convertName(dict.colors[sash.filling.color.inner.id]),
                outer: this.convertName(dict.colors[sash.filling.color.outer.id]),
              };

        if (newSash.glazing.selectedColor) {
            if (sash.filling.color.inner) {
                newSash.glazing.selectedColor.inner.isDefault = sash.filling.color.inner.isDefault;
            }
            if (sash.filling.color.outer) {
                newSash.glazing.selectedColor.outer.isDefault = sash.filling.color.outer.isDefault;
            }
        }

        newSash.glazing.selectedColorSecond = !sash.filling.overlayColor
            ? null
            : {
                inner: this.convertName(dict.colors[sash.filling.overlayColor.inner.id]),
                outer: this.convertName(dict.colors[sash.filling.overlayColor.outer.id]),
              };
        if (newSash.glazing.selectedColorSecond) {
            if (sash.filling.overlayColor.inner) {
                newSash.glazing.selectedColorSecond.inner.isDefault = sash.filling.overlayColor.inner.isDefault;
            }
            if (sash.filling.overlayColor.outer) {
                newSash.glazing.selectedColorSecond.outer.isDefault = sash.filling.overlayColor.outer.isDefault;
            }
        }

        if (sash.panelInner && newSash.panelInner) {
            newSash.panelInner.selectedColor = !sash.panelInner.color
                ? null
                : {
                    inner: this.convertName(dict.colors[sash.panelInner.color.inner.id]),
                    outer: this.convertName(dict.colors[sash.panelInner.color.outer.id]),
                };
            newSash.panelInner.selectedColorSecond = !sash.panelInner.overlayColor
                ? null
                : {
                    inner: this.convertName(dict.colors[sash.panelInner.overlayColor.inner.id]),
                    outer: this.convertName(dict.colors[sash.panelInner.overlayColor.outer.id]),
                };

            if (newSash.panelInner.selectedColor) {
                if (sash.panelInner.color.inner) {
                    newSash.panelInner.selectedColor.inner.isDefault = sash.panelInner.color.inner.isDefault;
                }
                if (sash.panelInner.color.outer) {
                    newSash.panelInner.selectedColor.outer.isDefault = sash.panelInner.color.outer.isDefault;
                }
            }

            if (newSash.panelInner.selectedColorSecond) {
                if (sash.panelInner.overlayColor?.inner) {
                    newSash.panelInner.selectedColorSecond.inner.isDefault = sash.panelInner.overlayColor.inner.isDefault;
                }
                if (sash.panelInner.overlayColor?.outer) {
                    newSash.panelInner.selectedColorSecond.outer.isDefault = sash.panelInner.overlayColor.outer.isDefault;
                }
            }
        }
        return newSash;
    }

    private mapMullion(
        sashes: ActiveSash[],
        dict: WindowConfiguration['dictionary'],
        sashId: number = null
    ) {
        return (mullion: MullionV2): ActiveMullion => ({
            id: mullion.id,
            isDefault: mullion.isDefault,
            frameId: Common.isDefined(mullion.frameId) ? mullion.frameId : null,
            profileId: mullion.profileId,
            rx:
                mullion.direction === 'vertical'
                    ? mullion.position
                    : mullion.adjacentSashes.top.reduce((prev, sId) => {
                          const adjacentSash = sashes.filter(s => s.id === sId);
                          return adjacentSash.length > 0 && prev > adjacentSash[0].rx
                              ? adjacentSash[0].rx
                              : prev;
                      }, Number.MAX_VALUE),
            ry:
                mullion.direction === 'horizontal'
                    ? mullion.position
                    : mullion.adjacentSashes.left.reduce((prev, sId) => {
                          const adjacentSash = sashes.filter(s => s.id === sId);
                          return adjacentSash.length > 0 && prev > adjacentSash[0].ry
                              ? adjacentSash[0].ry
                              : prev;
                      }, Number.MAX_VALUE),
            rWidth: mullion.direction === 'horizontal' ? mullion.length : 1,
            rHeight: mullion.direction === 'vertical' ? mullion.length : 1,
            direction: mullion.direction,
            type: mullion.type,
            parentSash: Common.isDefined(sashId) ? sashId : null,
            active: 1,
            multiAlignLeft: Common.isArray(mullion.adjacentSashes.left)
                ? mullion.adjacentSashes.left.map(adjSash => sashes.find(s => s.id === adjSash))
                : [],
            multiAlignRight: Common.isArray(mullion.adjacentSashes.right)
                ? mullion.adjacentSashes.right.map(adjSash => sashes.find(s => s.id === adjSash))
                : [],
            multiAlignTop: Common.isArray(mullion.adjacentSashes.top)
                ? mullion.adjacentSashes.top.map(adjSash => sashes.find(s => s.id === adjSash))
                : [],
            multiAlignBottom: Common.isArray(mullion.adjacentSashes.bottom)
                ? mullion.adjacentSashes.bottom.map(adjSash => sashes.find(s => s.id === adjSash))
                : [],
            multiAlignLeftDiv: Common.isArray(mullion.perpendicularMullions.left)
                ? mullion.perpendicularMullions.left.map(adjDiv => ({ id: adjDiv }))
                : [],
            multiAlignRightDiv: Common.isArray(mullion.perpendicularMullions.right)
                ? mullion.perpendicularMullions.right.map(adjDiv => ({ id: adjDiv }))
                : [],
            multiAlignTopDiv: Common.isArray(mullion.perpendicularMullions.top)
                ? mullion.perpendicularMullions.top.map(adjDiv => ({ id: adjDiv }))
                : [],
            multiAlignBottomDiv: Common.isArray(mullion.perpendicularMullions.bottom)
                ? mullion.perpendicularMullions.bottom.map(adjDiv => ({ id: adjDiv }))
                : [],
            perpendicularAlignments: {
                top:
                    mullion.perpendicularAlignments && mullion.perpendicularAlignments.top
                        ? mullion.perpendicularAlignments.top
                        : [],
                bottom:
                    mullion.perpendicularAlignments && mullion.perpendicularAlignments.bottom
                        ? mullion.perpendicularAlignments.bottom
                        : [],
                left:
                    mullion.perpendicularAlignments && mullion.perpendicularAlignments.left
                        ? mullion.perpendicularAlignments.left
                        : [],
                right:
                    mullion.perpendicularAlignments && mullion.perpendicularAlignments.right
                        ? mullion.perpendicularAlignments.right
                        : [],
            },
            parallelAlignments: {
                top:
                    mullion.parallelAlignments && mullion.parallelAlignments.top
                        ? mullion.parallelAlignments.top
                        : [],
                bottom:
                    mullion.parallelAlignments && mullion.parallelAlignments.bottom
                        ? mullion.parallelAlignments.bottom
                        : [],
                left:
                    mullion.parallelAlignments && mullion.parallelAlignments.left
                        ? mullion.parallelAlignments.left
                        : [],
                right:
                    mullion.parallelAlignments && mullion.parallelAlignments.right
                        ? mullion.parallelAlignments.right
                        : [],
            },
            reinforcement:
                mullion.reinforcement != null
                && this.convertName(dict.reinforcements[mullion.reinforcement]) || null,
            figure: {},
            cutAngle: 0,
            valid: true,
        });
    }

    private mapCoupling(coupling: Coupling, dict: WindowConfiguration['dictionary']): CouplingActive {
        const newCoupling: CouplingActive = {
            ...coupling,
            reinforcement:
                (coupling.reinforcement != null && {
                    id: coupling.reinforcement,
                    extId: dict.reinforcements[coupling.reinforcement].extId,
                    name: dict.reinforcements[coupling.reinforcement].name[this.langCode],
                })
                || null,
            color: coupling.color && {
                outer: coupling.color.outer?.id ? this.convertName(dict.constrColors[coupling.color.outer.id]) : null,
                inner: coupling.color.inner?.id ? this.convertName(dict.constrColors[coupling.color.inner.id]) : null,
                core: coupling.color.core?.id ? this.convertName(dict.colors[coupling.color.core.id]) : null,
                alushell: coupling.color.alushell?.id ? this.convertName(dict.colors[coupling.color.alushell.id]) : null,
            },
            wood: coupling.wood?.id ? {
                id: coupling.wood.id,
                name: coupling.wood.name || '',
            } : null,
        };

        if (newCoupling.color) {
            if (coupling.color.outer && newCoupling.color.outer) {
                newCoupling.color.outer.isDefault = coupling.color.outer.isDefault;
            }
            if (coupling.color.inner && newCoupling.color.inner) {
                newCoupling.color.inner.isDefault = coupling.color.inner.isDefault;
            }
            if (coupling.color.core && newCoupling.color.core) {
                newCoupling.color.core.isDefault = coupling.color.core.isDefault;
            }
            if (coupling.color.alushell && newCoupling.color.alushell) {
                newCoupling.color.alushell.isDefault = coupling.color.alushell.isDefault;
            }
        }

        return newCoupling;
    }

    private mapSideProfiles(
        sideProfile: SideProfile,
        dict: WindowConfiguration['dictionary']
    ): SideProfileActive {
        const sideProfileActive: SideProfileActive = {
            ...sideProfile,
            reinforcement:
                (sideProfile.reinforcement != null && {
                    id: sideProfile.reinforcement,
                    name: dict.reinforcements[sideProfile.reinforcement].name[this.langCode],
                    price: dict.reinforcements[sideProfile.reinforcement].price
                })
                || null,
            color: sideProfile.color && {
                outer: sideProfile.color.outer?.id ? this.convertName(dict.constrColors[sideProfile.color.outer.id]) : null,
                inner: sideProfile.color.inner?.id ? this.convertName(dict.constrColors[sideProfile.color.inner.id]) : null,
                core: sideProfile.color.core?.id ? this.convertName(dict.colors[sideProfile.color.core.id]) : null,
                alushell: sideProfile.color.alushell?.id ? this.convertName(dict.colors[sideProfile.color.alushell.id]) : null,
            },
            wood: sideProfile.wood?.id ? {
                id: sideProfile.wood.id,
                name: sideProfile.wood.name || '',
            } : null,
        };

        if (sideProfileActive.color) {
            if (sideProfile.color?.outer && sideProfileActive.color.outer) {
                sideProfileActive.color.outer.isDefault = sideProfile.color.outer.isDefault;
            }
            if (sideProfile.color?.inner && sideProfileActive.color.inner) {
                sideProfileActive.color.inner.isDefault = sideProfile.color.inner.isDefault;
            }
            if (sideProfile.color?.core && sideProfileActive.color.core) {
                sideProfileActive.color.core.isDefault = sideProfile.color.core.isDefault;
            }
            if (sideProfile.color?.alushell && sideProfileActive.color.alushell) {
                sideProfileActive.color.alushell.isDefault = sideProfile.color.alushell.isDefault;
            }
        }

        return sideProfileActive;
    }

    private mapFrame(frame: FrameV2, dict: WindowConfiguration['dictionary']): Frame {
        return {
            ...frame,
            frame: frame.frame.map(f => ({
                ...f,
                reinforcement:
                    (f.reinforcement != null && {
                        id: f.reinforcement,
                        extId: dict.reinforcements[f.reinforcement].extId,
                        name: dict.reinforcements[f.reinforcement].name[this.langCode],
                    })
                    || null,
            })),
        };
    }

    protected convertName<
        T extends {
            name: Record<string, string>;
        }
    >(
        obj: T
    ): T & {
        name: string;
    } {
        return obj
            ? {
                  ...obj,
                  name: obj?.name?.[this.langCode] || '',
              }
            : {
                  ...obj,
                  name: '',
              };
    }
}

export type IWindowActiveConfiguration = Pick<
    WindowActiveConfiguration,
    keyof WindowActiveConfiguration
>;
