import { isDefined, isUndefined, StepsService, IssuesService, InfoService, IssueLevel, ChangedStepValue } from '@icc/helpers';
import { Optional, Inject, Injectable } from '@angular/core';
import {
    TranslateService,
    core,
    ConfigurationsService,
    ConfiguratorsDataService,
    APP_CONFIG,
    AppConfigFactory,
    EventBusService,
    ValidationService,
    WindowActiveConfiguration,
    ParametersService,
    ProfileSetsService,
    ProfilesService,
} from '@icc/common';
import { HandlesService } from '../handles/handles.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService } from '@icc/price';
import { MuntinsService } from '../muntins/muntins.service';
import { ConstructionLimitationService } from '../dimensions/construction-limitation.service';
import { LocksService } from '../../door/locks/locks.service';
import { WarrantyService } from '@icc/legacy/price/warranty.service';
import { DependenciesService } from '@icc/legacy/dependencies/dependencies.service';
import { LayoutService } from '@icc/legacy/configurator/layout/layout.service';
import { DiscountsAndMultipliersService } from '@icc/price/b2b';
import { FramesService } from '@icc/common/layout/frames.service';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { SashesService } from '../sashes/sashes.service';
import { IccSystem, IccMaterial, IccSystemPacket } from '@icc/common/data-types';
import { WarmEdgesService } from '../glazings/warm-edges.service';
import { SealColorsService } from '../glazings/seal-colors.service';
import { SiliconeColorsService } from '../glazings/silicone-colors.service';
import { AccessoriesService } from '../accessories/accessories.service';
import { SchemasService } from '../../roller_shutter/schemas.service';
import { HardwareService } from 'libs/configurator/door/src/lib/hardware/hardware.service';
import { ResizeService } from '@icc/legacy/configurator/layout/resize.service';
import { SystemsService as MosquitoSystemsService } from '../../mosquito/systems.service'
import { FillingsService } from '../glazings/fillings.service';
import { SizesService } from 'libs/configurator/door/src/lib/sizes/sizes.service';
import { NewColorsService } from 'libs/configurator/window/src/lib/colors/new-colors.service';
import { AlushellService } from 'libs/configurator/window/src/lib/construction/alushell.service';
import { SetSizesService } from 'libs/configurator/door/src/lib/sizes/set-sizes.service';
import { WoodService } from 'libs/configurator/window/src/lib/construction/wood.service';


@Injectable()
export class SystemsService {
    private configurators = ['window', 'hs', 'door', 'folding_door', 'sliding_door'];

    typesNames: Record<IccMaterial, string>;
    systems: IccSystem[] = [];
    allSystems: IccSystem[] = [];
    systemPackets: IccSystemPacket[] = [];
    selectedSystems: IccSystem[] = [];
    systemTypes: {
        name: string;
        type: IccMaterial;
        static: IccMaterial;
    }[] = [];
    tags: any[] = [];
    groups: any[] = [];
    minHeightBalcony = 1600;
    maxHeightBalcony = 1800;
    loadedData = false;
    sashFramesOverlap = [];
    dividerOverlap = [];
    switchFromGreater = false;

    constructor(
        @Optional() @Inject('$rootScope') private $rootScope: any,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private handlesService: HandlesService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private configuratorsDataService: ConfiguratorsDataService,
        private stepsService: StepsService,
        private priceService: PriceService,
        private newColorsService: NewColorsService,
        private woodService: WoodService,
        private accessoriesService: AccessoriesService,
        private muntinsService: MuntinsService,
        private issuesService: IssuesService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private constructionLimitationService: ConstructionLimitationService,
        private locksService: LocksService,
        private hardwareService: HardwareService,
        private eventBusService: EventBusService,
        private validationService: ValidationService,
        private schemasService: SchemasService,
        private layoutService: LayoutService,
        private mosquitoSystemsService: MosquitoSystemsService,
        private discountsAndMultipliersService: DiscountsAndMultipliersService,
        private infoService: InfoService,
        private sealColorsService: SealColorsService,
        private siliconeColorsService: SiliconeColorsService,
        private dependenciesService: DependenciesService,
        private parametersService: ParametersService,
        private warrantyService: WarrantyService,
        private profileSetsService: ProfileSetsService,
        private profilesService: ProfilesService,
        private sashesService: SashesService,
        private framesService: FramesService,
        private warmEdgesService: WarmEdgesService,
        private resizeService: ResizeService,
        private fillingsService: FillingsService,
        private sizesService: SizesService,
        private alushellService: AlushellService,
        private setSizesService: SetSizesService,
    ) {
        this.init();
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribeWithoutConfiguration<ChangedStepValue>('changedStep', data => {
            if (this.configurators.indexOf(this.currentConfiguratorService.conf) !== -1) {
                if (data.value.nextStep.i < data.value.prevStep.i) {
                    this.switchFromGreater = true;
                } else {
                    this.switchFromGreater = false;
                }

                if (
                    data.value.nextStep.code !== 'system'
                    && this.stepsService.getStepByCode('system') > -1
                    && data.value.nextStep.i > this.stepsService.getStepByCode('system')
                ) {
                    if (isUndefined(this.configurationsService.conf.Current.System.id)) {
                        this.issuesService.simpleRegister(
                            'no-system',
                            'Brak wybranego systemu.',
                            this.translateService.instant('WINDOW|Brak wybranego systemu.'),
                            this.configurationsService.conf.Current,
                            {
                                logLevel: IssueLevel.NONE,
                            }
                        );
                        this.stepsService.selectStep(data.value.prevStep.i);
                    } else {
                        this.issuesService.unregister(
                            'no-system',
                            this.configurationsService.conf.Current
                        );
                    }
                }
            }
        });
    }

    private init() {
        this.typesNames = {
            pvc: this.translateService.instant('WINDOW|PVC'),
            alu: this.translateService.instant('WINDOW|ALU'),
            wood: this.translateService.instant('WINDOW|Drewno'),
        };
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }
        const allSystems = this.getSystems();
        this.systems = allSystems.filter(system => !system.subsystem);
        this.allSystems = allSystems;
        this.systemTypes = this.getSystemTypes();
        this.tags = (this.configuratorsDataService.data.systemsTags || []).filter(
            tag => tag.type === 'window_line'
        );
        this.groups = this.configuratorsDataService.data.systemGroups || [];
        this.systemPackets = this.configuratorsDataService.data.systemPackets || [];
        this.setDefaultValues();

        this.loadedData = true;
    }

    getSystems(confType = this.currentConfiguratorService.conf): IccSystem[] {
        const unavailableSystems = this.discountsAndMultipliersService.getUnavailableSystems();
        if (this.configuratorsDataService.data.windowLines) {
            switch (confType) {
                case 'window':
                    return this.configuratorsDataService.data.windowLines.filter(
                        el => unavailableSystems.indexOf(el.id) < 0
                        // && (!this.$rootScope.coupledPosition
                        // || !this.coupledWindowService.couplingOptions.matchedWindowSystems
                        // || this.coupledWindowService.couplingOptions.matchedWindowSystems.indexOf(
                        //     Number(el.id)
                        // ) > -1)
                    );
                case 'hs':
                    return this.configuratorsDataService.data.hsLines.filter(
                        el => unavailableSystems.indexOf(el.id) < 0
                        // && (!this.$rootScope.coupledPosition
                        // || !this.coupledWindowService.couplingOptions.matchedWindowSystems
                        // || this.coupledWindowService.couplingOptions.matchedWindowSystems.indexOf(
                        //     Number(el.id)
                        // ) > -1)
                    );
                case 'door':
                    return this.configuratorsDataService.data.doorLines.filter(
                        el => unavailableSystems.indexOf(el.id) < 0
                        // && (!this.$rootScope.coupledPosition
                        // || !this.coupledWindowService.couplingOptions.matchedWindowSystems
                        // || this.coupledWindowService.couplingOptions.matchedWindowSystems.indexOf(
                        //     Number(el.id)
                        // ) > -1)
                    );
                case 'folding_door':
                    return this.configuratorsDataService.data.foldingDoorLines.filter(
                        el => unavailableSystems.indexOf(el.id) < 0
                        // && (!this.$rootScope.coupledPosition
                        // || !this.coupledWindowService.couplingOptions.matchedWindowSystems
                        // || this.coupledWindowService.couplingOptions.matchedWindowSystems.indexOf(
                        //     Number(el.id)
                        // ) > -1)
                    );
                case 'sliding_door':
                    return this.configuratorsDataService.data.slidingDoorLines.filter(
                        el => unavailableSystems.indexOf(el.id) < 0
                        // && (!this.$rootScope.coupledPosition
                        // || !this.coupledWindowService.couplingOptions.matchedWindowSystems
                        // || this.coupledWindowService.couplingOptions.matchedWindowSystems.indexOf(
                        //     Number(el.id)
                        // ) > -1)
                    );
            }
        }
        return [];
    }

    selectDestination(destination) {
        if (
            DoorActiveConfiguration.is(this.configurationsService.conf.Current)
            && DoorActiveConfiguration.is(this.configurationsService.conf.Default)
        ) {
            this.configurationsService.conf.Current.destination = destination;
            this.configurationsService.conf.Default.destination = destination;
            this.stepsService.goToNextStep(true);
        }
    }

    selectSystem(
        conf: WindowActiveConfiguration,
        defaultConf,
        parentSystem: IccSystem,
        nextStep?: boolean,
        price?: boolean,
        changeLayout?: boolean,
        defaultValue = false,
        editedPosition = !!this.configurationsService.conf.Edit,
        openConfirmModal = false,
        defaultSystem = false,
        fromSystemsComparison = false
    ) {
        let system;
        if (fromSystemsComparison) {
            system = parentSystem;
        } else {
            system = this.getMatchingSystem(parentSystem, conf, conf.systemPackets) || parentSystem;
        }
        if ((!system || conf.System && conf.System.id === system.id) && !defaultValue) {
            return;
        }
        if (this.currentConfiguratorService.conf === 'door' && this.config().IccConfig.Configurators.systemChangeConfirmModal?.door && openConfirmModal || this.currentConfiguratorService.conf !== 'door' && this.config().IccConfig.Configurators.systemChangeConfirmModal.window && openConfirmModal) {
            this.infoService.openConfirmModal(
                this.translateService.instant('WINDOW|Zmiana systemu'),
                this.translateService.instant(
                    'WINDOW|Zmiana systemu może zresetować niektóre ustawienia. Czy na pewno chcesz zmienić system?'
                ),
                [
                    {
                        name: this.translateService.instant('INTERFACE|Tak'),
                        callback: () => {
                            this.setSystem(conf, system, defaultConf, changeLayout, editedPosition, nextStep, price);
                        }
                    },
                    {
                        name: this.translateService.instant('INTERFACE|Nie'),
                        callback: () => {
                            return;
                        },
                    }
                ]
            );
        } else {
            this.setSystem(conf, system, defaultConf, changeLayout, editedPosition, nextStep, price, defaultSystem);
        }
    }

    // eslint-disable-next-line max-params, max-statements
    private setSystem(conf: WindowActiveConfiguration, system: IccSystem, defaultConf: any, changeLayout: boolean, editedPosition: boolean, nextStep: boolean, price: boolean, defaultSystem = false) {
        if (this.currentConfiguratorService.conf === 'window') {
            if (!system.different_sash_color) {
                this.newColorsService.setExtendedMode(false, conf);
            }
            if (this.config().IccConfig.Configurators.steelSelect) {
                if (system.steel_disabled) {
                    conf.Steel = null;
                    defaultConf.Steel = null;
                } else if (conf.Steel === null) {
                    const steel = system.steel_closed ? 'Closed' : 'Opened';
                    conf.Steel = steel;
                    defaultConf.Steel = steel;
                }
            }
        }
        const oldSystem = conf.System;
        const newSystem =
            conf.System
            && conf.System.id
            && Number(conf.System.id) === Number(system.id)
                ? false
                : true;
        conf.System = system;
        defaultConf.System = system;
        conf.systemPackets = conf.System.subsystem && conf.System.packets || [];

        this.validationService.someIndeterminate(conf, [
            'sashes',
            'shape',
            'colors',
            'loadedMuntinsColors',
            'loadedProfiles',
            'sealColor',
            'loadedThresholdColor'
        ]);
        Object.assign(conf, this.validationService.valid(conf, 'system'));
        let pauseId: number;
        if (
            changeLayout
            && oldSystem
            && oldSystem.confType !== system.confType
            && conf.type === 'sliding_door'
        ) {
            pauseId = this.eventBusService.pause([
                'changedSashes',
                'processDependencies',
                'changedFrames',
            ]);
            this.layoutService.resetLayout(true);
            this.layoutService.selectDefaultLayout();
        }

        this.eventBusService.post({
            key: 'setSystem',
            value: {
                systemId: system.id,
                newSystem,
                defaultSystem
            },
            conf,
            defaultConf,
        });
        conf.Name =
            (system.type && this.typesNames[system.type]
                ? this.typesNames[system.type] + ' > '
                : '') + system.name;
        defaultConf.Name =
            (system.type && this.typesNames[system.type]
                ? this.typesNames[system.type] + ' > '
                : '') + system.name;

        if (system.steel_closed && conf.Steel !== 'Closed') {
            conf.Steel = 'Closed';
        }

        this.locksService.findLocksBySystem(conf as DoorActiveConfiguration, defaultConf);
        if (
            DoorActiveConfiguration.is(conf)
            && this.config().IccConfig.Configurators.door.advancedHardware
        ) {
            this.hardwareService.findHardwareBySystem(conf);
        }
        this.accessoriesService.findAccessories(conf);
        this.alushellService.hasSystemAlushell(conf);
        this.woodService.validateAndFixWood(conf, defaultConf);
        this.newColorsService.setDefaultColors(conf, defaultSystem);

        if (
            changeLayout
            && oldSystem
            && oldSystem.confType !== system.confType
            && conf.type === 'sliding_door'
        ) {
            this.eventBusService.resume(
                ['changedSashes', 'processDependencies', 'changedFrames'],
                pauseId
            );
        }


        if (!editedPosition) {
            this.handlesService.setHandleType(conf.HandleType, conf);

            defaultConf.Monoblock = {};
            conf.Monoblock = {};
        }

        this.muntinsService.changeType(conf);

        // sprawdź dostępność słupków i skrzydeł
        this.constructionLimitationService.findReinforcement(conf);
        this.checkConstructionStepAvailability();

        if (nextStep) {
            if (
                this.currentConfiguratorService.conf !== 'door'
                || this.config().IccConfig.Configurators.door.version > 1
            ) {
                this.stepsService.goToNextStep(true);
            }
        }
        if (price) {
            this.priceService.count();
        }

        if (this.config().IccConfig.Configurators.dependencies) {
            this.eventBusService.post({ key: 'processDependencies', value: null, conf });
        }

        if (this.config().IccConfig.Configurators.active.includes('mosquito')) {
            this.mosquitoSystemsService.valid(conf);
        }

        this.handlesService.refreshTypes(conf);
        this.schemasService.init();
    }

    getSystemType() {
        const systemTypes = this.getSystemTypes();
        if (
            isDefined(this.configurationsService.conf.Current.System)
            && isDefined(this.configurationsService.conf.Current.System.type)
        ) {
            const type = systemTypes.find(
                t => t.type === this.configurationsService.conf.Current.System.type
            );
            if (type) {
                return type;
            }
        }

        return systemTypes[0];
    }

    getSystemTypes() {
        return this.systems
            .map(system => system.type)
            .filter((x, i, a) => a.indexOf(x) === i)
            .map(type => ({
                name: this.typesNames[type],
                type,
                static: type,
            }));
    }

    setDefaultValues() {
        const adminThumb = ~~sessionStorage.getItem('adminThumb');
        if (
            this.config().IccConfig.Configurators.autoSelectSystem
            || (this.configurationsService.conf.Default.System
                && this.configurationsService.conf.Default.System.id)
            || this.currentConfiguratorService.conf === 'door'
            || adminThumb
        ) {
            const defaultLayoutId = adminThumb;
            const defaultLayouts = this.layoutService.defaultLayouts;
            const defaultLayout = defaultLayoutId && defaultLayouts.find(l => l.SashesLayoutsVariant.id == defaultLayoutId);
            const systems = defaultLayout ? this.allSystems.filter(s => !defaultLayout.SashesLayoutsVariant.not_systems.includes(String(s.id))) : this.allSystems;
            let system = adminThumb && systems[0];

            if (!system?.id) {
                system = core.fIdO(
                    systems,
                    this.configurationsService.conf.Default.System.id
                );
            }
            if (!system?.id) {
                system = this.systems[0];
            }
            // If the system is a subsystem then select all its packets
            if (system.subsystem) {
                this.setPackets(system.packets);
            }
            this.selectSystem(
                this.configurationsService.conf.Current,
                this.configurationsService.conf.Default,
                system,
                false,
                true,
                true,
                true,
                !!this.configurationsService.conf.Edit,
                false,
                true
            );
        }
    }

    checkConstructionStepAvailability() {
        const constructionStepAvailable =
            (this.configurationsService.conf.Current.type !== 'door'
                && this.config().IccConfig.Configurators.window.additionalOptionsInAccessory)
            || (this.config().IccConfig.Configurators.alushell
                && this.configurationsService.conf.Current.System.alushell)
            || this.configurationsService.conf.Current.System.type === 'wood';
        if (constructionStepAvailable) {
            this.stepsService.enable('construction');
        } else {
            this.stepsService.disable('construction');
        }
    }

    selectPackets(
        packetIds: number[],
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        defaultConf,
        nextStep?: boolean,
        price?: boolean,
        changeLayout?: boolean,
        editedPosition = !!this.configurationsService.conf.Edit
    ) {
        this.setPackets(packetIds);
        this.selectSystem(
            conf,
            defaultConf,
            conf.System,
            nextStep,
            price,
            changeLayout,
            editedPosition
        );
        if (DoorActiveConfiguration.is(conf)) {
            this.setSizesService.findMatchingDoorSizes(conf, true);
        }
    }

    setPackets(packetIds: number[]) {
        this.configurationsService.conf.Current.systemPackets = packetIds || [];
    }

    getMatchingSystem(existingSystem: IccSystem, conf: WindowActiveConfiguration, systemPackets = conf.systemPackets || [], showWarning = false) {
        const parentSystem = this.getParentSystem(existingSystem);
        if (systemPackets.length === 0) {
            return parentSystem;
        }
        const matchingSystem = this.allSystems.find(
            s =>
            systemPackets.sort().join(',') === s.packets.sort().join(',') // Check if packet ids are the same
            && Number(s.parent_id) === Number(parentSystem.id)
        );
        if (matchingSystem) {
            return matchingSystem;
        } else {
            if (showWarning) {
                this.infoService.openWarning(
                    this.translateService.instant('WINDOW|Niedostępna kombinacja pakietów')
                );
            }
        }
    }

    getParentSystem(existingSystem: IccSystem) {
        return existingSystem && existingSystem.subsystem
            ? this.allSystems.find(s => !s.subsystem && Number(existingSystem.parent_id) === Number(s.id))
            : existingSystem;
    }

    getMatchingPackets(existingSystem: IccSystem, conf: WindowActiveConfiguration, systemPackets = conf.systemPackets) {
        const parentSystem = this.getParentSystem(existingSystem);
        const matchingPackets: number[] = this.allSystems.reduce(
            (packets, s) => {
                if ((systemPackets.length === 0 || systemPackets.some(p => s.packets && s.packets.includes(p)))
                    && parentSystem
                    && Number(s.parent_id) === Number(parentSystem.id)) {
                    s.packets.forEach(p => {
                        if (!packets.includes(p)) {
                            packets.push(p);
                        }
                    });
                }
                return packets;
            }, []);
        return matchingPackets;
    }

    isAnySystemDoorType(): boolean {
        return DoorActiveConfiguration.is(this.configurationsService.conf.Current) && this.allSystems.find(s => s.door_type) ? true : false;
    }
}
